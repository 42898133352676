import React, { Component } from 'react';
import axios from 'axios';
import { Container, Col, Row, ThemeProvider } from 'react-bootstrap';
import TitleAndMetaTags from 'components/TitleAndMetaTags';
import Layout from 'components/Layout';
import { urlRoot } from 'site-constants';
import { sharedStyles } from 'theme';
import copy from 'copy-to-clipboard';
import luxon from 'luxon';
// import print from 'ink-html';
import fetch from 'cross-fetch';
import Typed from 'react-typed';

import apiCorano from '../components/Apis/api-corano.js';
import apiIstikhara from '../components/Apis/api-istikhara.js';
import apiMatematica from '../components/Apis/api-matematica.js';
import apiInglese from '../components/Apis/api-inglese.js';
import apiGeolocalizzazioneIp from '../components/Apis/api-geolocalizzazione.js';
import apiNinjas from '../components/Apis/api-ninjas.js';
import apiMonitoraggioIp from '../components/Apis/api-monitoraggio-ip.js';
import apiAltitudine from '../components/Apis/api-altitudine.js';
import VisitFunzioni from '../components/Apis/visit-funzioni.js';

import mostrepeatedletter from 'most-repeated-letter';
import caesarcipher from 'caesarcipher2';
import spellinteger from 'spell-integer';
import removeemoji from 'remove-emoji';
import randopass from 'randopass';
import testpalindrome from 'test-palindrome';
import arabictoroman from 'arabictoroman';
import celsiusfahrenheitkelvin from 'celsiusfahrenheitkelvin';
import removeaccent from 'removeaccent';
import fibonacci from 'fibonacci-calc';
import abjad from 'abjad-calc';
import MatrixBackground from '../components/matrix.jsx';

class Funzioni extends Component {
  constructor(props) {
    super(props);
    this.state = {
      output1: null, output2: null, output3: null, output4: null, output5: null, output6: null, output7: null, output8: null, output9: null, output10: null, output11: null, output12: null, output13: null, nameUniversita: '', countryUniversita: '', output23: null,
      id: '',
      output: '',
    };
  }

  async callAPI() {

    if (!window.location.href.includes('localhost' & '127.0.0.1')) {

      await

        fetch("https://server-mostafamilani.iran.liara.run/record/63d7484b07bd5d81cb468dd9", {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(res => res.text())
          .then(res => this.setState({ apiResponse0: JSON.parse(res).contatore + 1 }));

      fetch("https://server-mostafamilani.iran.liara.run/update/63d7484b07bd5d81cb468dd9", {
        method: "POST",
        body: JSON.stringify({
          contatore: Number(this.state.apiResponse0),
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.text())
        .then(res => this.setState({ apiResponse: res }));


      fetch("https://server-mostafamilani.iran.liara.run/record/63d7484b07bd5d81cb468dd9", {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.text())
        .then(res => this.setState({ apiResponse2: JSON.parse(res).contatore }));

    }

  }

  handleChangeSura = event => {
    this.setState({ sura: event.target.value });
  }

  handleChangeVersetto = event => {
    this.setState({ versetto: event.target.value });
  }

  handleSubmitCorano = async event => {
    event.preventDefault();
    await apiCorano.get(`${this.state.sura}:${this.state.versetto}`)
      .then(res => {
        // console.log(res);
        this.setState({
          output:

            <>
              <p>{JSON.stringify(res.data.data.text).replace(/\\n|\"/g, '')}</p>
              <p>abjad = {abjad(JSON.stringify(res.data.data.text).replace(/\\n|\"/g, ''))}</p>
              <img src={`https://everyayah.com/data/images_png/${this.state.sura}_${this.state.versetto}.png`} />
            </>,

        });
      });
  }

  handleIstikhara = async () => {
    await apiIstikhara.get(`${Math.floor(Math.random() * (604) + 1)}`)
      .then(res => {
        console.log(res);
        this.setState({
          output:

            <>
              <p>{JSON.stringify(res.data.data.ayahs[0].text).replace(/\\n|\"/g, '')}</p>
              <p>abjad = {abjad(JSON.stringify(res.data.data.ayahs[0].text).replace(/\\n|\"/g, ''))}</p>
              <img src={`https://quran.ksu.edu.sa/png_big/${res.data.data.number}.png`} />
            </>,

        });
      });
  }

  handleChangeMeteo = event => {
    this.setState({ meteo: event.target.value });
  }

  handleSubmitMeteo = async event => {
    event.preventDefault();
    await apiNinjas.get(`weather?city=${this.state.meteo}`)
      .then(res => {
        console.log(res);
        this.setState({
          output12: <>
            <p><b>Percentuale di nuvole:</b> {JSON.stringify(res.data.cloud_pct)}%</p>
            <p><b>Temperatura:</b> {JSON.stringify(res.data.temp)}°C</p>
            <p><b>Temperatura percepita:</b> {JSON.stringify(res.data.feels_like)}°C</p>
            <p><b>Umidità:</b> {JSON.stringify(res.data.humidity)}%</p>
            <p><b>Temperatura minima:</b> {JSON.stringify(res.data.min_temp)}°C</p>
            <p><b>Temperatura massima:</b> {JSON.stringify(res.data.max_temp)}°C</p>
            <p><b>Velocità del vento:</b> {Math.round(((JSON.stringify(res.data.wind_speed)) * 3.6) * 1000) / 1000} km/h</p>
            <p><b>Direzione (gradi) del vento:</b> {JSON.stringify(res.data.wind_degrees)}°</p>
            {/* <p><b>Sorgere del Sole:</b> {new Date(JSON.stringify(res.data.sunrise) * 1000).getHours() + ':' + ("0" + new Date(JSON.stringify(res.data.sunrise) * 1000).getMinutes()).substr(-2) + ':' + ("0" + new Date(JSON.stringify(res.data.sunrise) * 1000).getSeconds()).substr(-2)}</p>
          <p><b>Tramonto del Sole:</b> {new Date(JSON.stringify(res.data.sunset) * 1000).getHours() + ':' + ("0" + new Date(JSON.stringify(res.data.sunset) * 1000).getMinutes()).substr(-2) + ':' + ("0" + new Date(JSON.stringify(res.data.sunset) * 1000).getSeconds()).substr(-2)}</p> */}
          </>,
        });
      })
      .catch(err => {
        this.setState({
          output12: "⚠️ Impossibile trovare la città!"
        })
      })
    // documentazione: https://api-ninjas.com/api/worldtime
  }

  handleChangeOperazione = event => {
    this.setState({ operazione: event.target.value });
  }

  handleChangeEspressione = event => {
    this.setState({ espressione: event.target.value });
  }

  handleSubmitMatematica = async event => {
    event.preventDefault();
    await apiMatematica.get(`${this.state.operazione}/${this.state.espressione}`)
      .then(res => {
        this.setState({ output13: JSON.stringify(res.data.result).replace(/"/g, '') });
      });
  }

  handleChangeInglese = event => {
    this.setState({ inglese: event.target.value });
  }

  handleSubmitInglese = async event => {
    event.preventDefault();
    await apiInglese.get(`${this.state.inglese}`)
      .then(res => {
        this.setState({
          output14:
            <><br /><h3>{JSON.stringify(res.data[0].phonetics[1].text).replace(/"/g, '')}</h3>
              <audio autoPlay>
                <source src={JSON.stringify(res.data[0].phonetics[1].audio).replace(/"/g, '')} type="audio/mpeg" />
              </audio>
            </>,
        });
      });
  }

  handleSubmitDizionarioInglese = async event => {
    event.preventDefault();
    await apiNinjas.get(`dictionary?word=${this.state.inglese}`)
      .then(res => {
        console.log(res);
        this.setState({
          output14:
            <>
              <br />
              <p><b>Parola:</b> {JSON.stringify(res.data.word).replace(/"/g, '')}</p>
              <p><b>È una parola valida?</b> {JSON.stringify(res.data.valid) === 'true' ? "Sì!" : "No!"}</p>
              <p><b>Definizione:</b> {res.data.definition}</p>
            </>
        });
      })
      .catch(err => {
        this.setState({
          output14: "⚠️ Parola non valida!"
        })
      })
  }

  handleSubmitSinonimiContrariInglese = async event => {
    event.preventDefault();
    await apiNinjas.get(`thesaurus?word=${this.state.inglese}`)
      .then(res => {
        console.log(res);
        this.setState({
          output14:
            <>
              <br />
              <p><b>Parola:</b> {JSON.stringify(res.data.word).replace(/"/g, '')}</p>
              <p><b>Sinonimi:</b> {res.data.synonyms.join(' - ')}</p>
              <p><b>Contrari:</b> {res.data.antonyms.join(' - ')}</p>
            </>
        });
      })
      .catch(err => {
        this.setState({
          output14: "⚠️ Parola non valida!"
        })
      })
  }

  handleSubmitParoleInRima = async event => {
    event.preventDefault();
    await apiNinjas.get(`rhyme?word=${this.state.inglese}`)
      .then(res => {
        console.log(res);
        this.setState({
          output14: res.data.join(' - ') === '' ? <><br /> ⚠️ Non è stata trovata nessuna parola in rima! </> :
            <>
              <br />
              <p><b>Parola:</b> {this.state.inglese}</p>
              <p><b>Parole in rima:</b> {res.data.join(' - ')}</p>
            </>
        });
      })
      .catch(err => {
        this.setState({
          output14: <><br /> ⚠️ Non è stata trovata nessuna parola in rima! </>
        })
      })
  }

  handleSubmitParoleCasualiInglesi = async event => {
    event.preventDefault();
    await apiNinjas.get(`randomword`)
      .then(res => {
        console.log(res);
        this.setState({
          output14:
            <>
              <br />
              <p><b>Parola casuale inglese:</b> {JSON.stringify(res.data.word).replace(/"/g, '')}</p>
            </>
        });
      });
  }

  handleChangeGeolocalizzazioneIp = event => {
    this.setState({ geolocalizzazioneIp: event.target.value });
  }

  handleSubmitGeolocalizzazioneIp = async event => {
    event.preventDefault();
    await apiGeolocalizzazioneIp.get(`ip?ip=${this.state.geolocalizzazioneIp}`)
      .then(res => {
        console.log(res);
        this.setState({
          output15: <>
            <p><b>IP utilizzato per la query:</b> "{this.state.geolocalizzazioneIp}"</p>
            <p><b>Stato della risposta dal server:</b> {JSON.stringify(res.data.status)}</p>
            <p><b>Messaggio dal server:</b> {JSON.stringify(res.data.message)}</p>
            <p><b>Continente:</b> {JSON.stringify(res.data.continentName)}</p>
            <p><b>Codice continente:</b> {JSON.stringify(res.data.continentCode)}</p>
            <p><b>Paese:</b> {JSON.stringify(res.data.country)}</p>
            <p><b>Codice paese:</b> {JSON.stringify(res.data.countryCode)}</p>
            <p><b>Bandiera:</b> {JSON.stringify(res.data.flag)}</p>
            <p><b>Prefisso telefonico:</b> {JSON.stringify(res.data.callingCode)}</p>
            <p><b>Fuso orario:</b> {JSON.stringify(res.data.timezone)}</p>
            <p><b>Moneta nazionale:</b> {JSON.stringify(res.data.currencyCode)}</p>
            <p><b>Simbolo moneta nazionale:</b> {JSON.stringify(res.data.currencySymbol_UTF8)}</p>
            <p><b>Convertitore di valuta (/$):</b> {JSON.stringify(res.data.currencyConverter)}</p>
            <p><b>Regione:</b> {JSON.stringify(res.data.region)}</p>
            <p><b>Nome regione:</b> {JSON.stringify(res.data.regionName)}</p>
            <p><b>Città:</b> {JSON.stringify(res.data.city)}</p>
            <p><b>Latitudine:</b> {JSON.stringify(res.data.lat)}</p>
            <p><b>Longitudine:</b> {JSON.stringify(res.data.lon)}</p>
            <p><b>CAP:</b> {JSON.stringify(res.data.zip)}</p>
            <p><b>Codice lingua:</b> {JSON.stringify(res.data.languageCode)}</p>
            <p><b>Codice DMA:</b> {JSON.stringify(res.data.dmaCode)}</p>
            <p><b>ISP:</b> {JSON.stringify(res.data.isp)}</p>
            <p><b>ASN:</b> {JSON.stringify(res.data.connection.asn)}</p>
            <p><b>Nome dellorganizzazione:</b> {JSON.stringify(res.data.org)}</p>
            <p><b>Proxy:</b> {JSON.stringify(res.data.security.is_proxy)}</p>
            <p><b>Tipo di proxy:</b> {JSON.stringify(res.data.security.proxy_type)}</p>
            <p><b>Crawler</b> {JSON.stringify(res.data.security.is_crawler)}</p>
            <p><b>Nome del crawler:</b> {JSON.stringify(res.data.security.crawler_name)}</p>
            <p><b>Tipo di crawler:</b> {JSON.stringify(res.data.security.crawler_type)}</p>
            <p><b>Tor:</b> {JSON.stringify(res.data.security.is_tor)}</p>
            <p><b>Livello di minaccia:</b> {JSON.stringify(res.data.security.threat_level)}</p>
            <p><b>Tipo di minaccia:</b> {JSON.stringify(res.data.security.threat_types)}</p>
            <p><b>Protocollo:</b> {JSON.stringify(res.data.type)}</p>
          </>,
        });
      });
  }

  handleChangeDnsLookupDomain = event => {
    this.setState({ dnsLookupDomain: event.target.value });
  }

  handleSubmitDnsLookup = async event => {
    event.preventDefault();
    await apiNinjas.get(`dnslookup?domain=${this.state.dnsLookupDomain}`)
      .then(res => {
        console.log(res);
        this.setState({
          output16: res.data.map((element) => <><br /><p>{JSON.stringify(element)}</p></>
          ),
        });
      });
  }

  handleChangeQualitaAriaCitta = event => {
    this.setState({ qualitaAriaCitta: event.target.value });
  }

  handleSubmitQualitaAria = async event => {
    event.preventDefault();
    await apiNinjas.get(`airquality?city=${this.state.qualitaAriaCitta}`)
      .then(res => {
        console.log(Object.entries(res.data));
        this.setState({
          output17: Object.entries(res.data).map((element) => <><br /><p><b>{element[0]}:</b> {JSON.stringify(element[1])}</p></>
          ),
        });
      });
    // documentazione: https://api-ninjas.com/api
  }

  handleChangeQrCodeFormat = event => {
    this.setState({ qrCodeFormat: event.target.value });
  }

  handleChangeQrCodeData = event => {
    this.setState({ qrCodeData: event.target.value });
  }

  handleChangeQrCodeSize = event => {
    this.setState({ qrCodeSize: event.target.value });
  }

  handleChangeQrCodeFgColor = event => {
    this.setState({ qrCodeFgColor: event.target.value });
  }

  handleChangeQrCodeBgColor = event => {
    this.setState({ qrCodeBgColor: event.target.value });
  }

  handleSubmitQrCode = async event => {
    event.preventDefault();
    await apiNinjas.get(`qrcode?format=${this.state.qrCodeFormat === undefined ? this.state.qrCodeFormat = 'png' : this.state.qrCodeFormat}&data=${this.state.qrCodeData === undefined ? alert('Inserisci un contenuto!') : this.state.qrCodeData}&size=${this.state.qrCodeSize === undefined ? this.state.qrCodeSize = 250 : this.state.qrCodeSize}&fg_color=${this.state.qrCodeFgColor === undefined ? this.state.qrCodeFgColor = '000000' : this.state.qrCodeFgColor}&bg_color=${this.state.qrCodeBgColor === undefined ? this.state.qrCodeBgColor = 'ffffff' : this.state.qrCodeBgColor}`)
      .then(res => {
        console.log(res);
        this.setState({
          output18:

            this.state.qrCodeData === undefined ? null : this.state.qrCodeFormat === 'svg'
              ? <><img height={this.state.qrCodeSize} width={this.state.qrCodeSize} src={`data:image/${this.state.qrCodeFormat}+xml;base64,${res.data}`} /><br /><a href={`data:image/${this.state.qrCodeFormat};base64,${res.data}`} download={`${Date.now()}.${this.state.qrCodeFormat}`}>Scarica l'immagine ({`${Date.now()}.${this.state.qrCodeFormat}`}) ⏬</a></>
              : <><img src={`data:image/${this.state.qrCodeFormat};base64,${res.data}`} /><br /><a href={`data:image/${this.state.qrCodeFormat};base64,${res.data}`} download={`${Date.now()}.${this.state.qrCodeFormat}`}>Scarica l'immagine ({Date.now()}.{this.state.qrCodeFormat}) ⏬</a></>,

        });
      });
    // documentazione: https://api-ninjas.com/api
  }

  handleChangeConvalidaTelefono = event => {
    this.setState({ convalidaTelefono: event.target.value });
  }

  handleSubmitConvalidaTelefono = async event => {
    event.preventDefault();
    await apiNinjas.get(`validatephone?number=${this.state.convalidaTelefono}`)
      .then(res => {
        console.log(res);
        this.setState({
          output19: <>
            <p>{JSON.stringify(res.data.is_valid) === "true" ? <b>Numero di telefono valido ✔️</b> : <b>Numero di telefono non valido ❌</b>}</p>
            <p>{JSON.stringify(res.data.is_formatted_properly) === "true" ? <b>Formato corretto ✔️</b> : <b>Formato non corretto ❌</b>}</p>
            <p><b>Paese:</b> {JSON.stringify(res.data.country)}</p>
            <p><b>Posizione:</b> {JSON.stringify(res.data.location)}</p>
            <p><b>Fuso orario:</b> {res.data.timezones.map(element => element)}</p>
            <p><b>Formato nazionale:</b> {JSON.stringify(res.data.format_national)}</p>
            <p><b>Formato internazionale:</b> {JSON.stringify(res.data.format_international)}</p>
            <p><b>Formato E.164:</b> {JSON.stringify(res.data.format_e164)}</p>
            <p><b>Prefisso internazionale:</b> {JSON.stringify(res.data.country_code)}</p>
          </>,
        });
      })
      .catch(err => {
        this.setState({
          output19: "⚠️ Impossibile analizzare il numero di telefono inserito!"
        })
      })
    // documentazione: https://api-ninjas.com/api/validatephone
  }

  handleChangeNumeroTelaio = event => {
    this.setState({ numeroTelaio: event.target.value });
  }

  handleSubmitNumeroTelaio = async event => {
    event.preventDefault();
    await apiNinjas.get(`vinlookup?vin=${this.state.numeroTelaio}`)
      .then(res => {
        console.log(res);
        this.setState({
          output20: <>
            <p><b>Numero di telaio:</b> {JSON.stringify(res.data.vin)}</p>
            <p><b>Paese:</b> {JSON.stringify(res.data.country)}</p>
            <p><b>Produttore:</b> {JSON.stringify(res.data.manufacturer)}</p>
            <p><b>Regione:</b> {JSON.stringify(res.data.region)}</p>
            <p><b>WMI:</b> {JSON.stringify(res.data.wmi)}</p>
            <p><b>VDS:</b> {JSON.stringify(res.data.vds)}</p>
            <p><b>VIS:</b> {JSON.stringify(res.data.vis)}</p>
            <p><b>Anno:</b> {res.data.years.map(element => element)}</p>
          </>,
        });
      })
      .catch(err => {
        this.setState({
          output20: <p>⚠️ Impossibile cercare il numero di telaio inserito!<br />Assicurati di inserire un numero valido di 17 caratteri.</p>
        })
      })
    // documentazione: https://api-ninjas.com/api/vinlookup
  }

  handleChangeOraMondiale = event => {
    this.setState({ oraMondiale: event.target.value });
  }

  handleSubmitOraMondiale = async event => {
    event.preventDefault();
    await apiNinjas.get(`worldtime?city=${this.state.oraMondiale}`)
      .then(res => {
        console.log(res);
        this.setState({
          output21:
            <>
              <p><b>Fuso orario:</b> {JSON.stringify(res.data.timezone).replace(/"/g, '')}</p>
              <p><b>Data e ora:</b> {JSON.stringify(res.data.datetime).replace(/"/g, '')}</p>
              <p><b>Giorno della settimana:</b> {
                JSON.stringify(res.data.day_of_week) === '"Sunday"' ? 'domenica' :
                  JSON.stringify(res.data.day_of_week) === '"Monday"' ? 'lunedì' :
                    JSON.stringify(res.data.day_of_week) === '"Tuesday"' ? 'martedì' :
                      JSON.stringify(res.data.day_of_week) === '"Wednesday"' ? 'mercoledì' :
                        JSON.stringify(res.data.day_of_week) === '"Thursday"' ? 'giovedì' :
                          JSON.stringify(res.data.day_of_week) === '"Friday"' ? 'venerdì' : 'sabato'
              }</p>
            </>,
        });
      })
      .catch(err => {
        this.setState({
          output21: "⚠️ Impossibile trovare la città!"
        })
      })
    // documentazione: https://api-ninjas.com/api/worldtime
  }

  handleChangeCitta = event => {
    this.setState({ citta: event.target.value });
  }

  handleSubmitCitta = async event => {
    event.preventDefault();
    await apiNinjas.get(`city?name=${this.state.citta}`)
      .then(res => {
        console.log(res);
        this.setState({
          output22: res.data.map(element =>
            <>
              <p><b>Nome (inglese) della città:</b> {JSON.stringify(element.name).replace(/"/g, '')}</p>
              <p><b>Latitudine:</b> {JSON.stringify(element.latitude)}</p>
              <p><b>Longitudine:</b> {JSON.stringify(element.longitude)}</p>
              <p><b>Paese:</b> {JSON.stringify(element.country).replace(/"/g, '')}</p>
              <p><b>Popolazione:</b> {JSON.stringify(element.population)}</p>
              <p><b>È una capitale?</b> {JSON.stringify(element.is_capital === true ? "Sì!" : "No!").replace(/"/g, '')}</p>
            </>),
        });
      })
      .catch(err => {
        this.setState({
          output22: "⚠️ Impossibile trovare la città!"
        })
      })
    // documentazione: https://api-ninjas.com/api/worldtime
  }

  handleChangeMonitoraggioIp = event => {
    this.setState({ monitoraggioIp: event.target.value });
  }

  handleSubmitMonitoraggioIp = async event => {
    event.preventDefault();
    await apiMonitoraggioIp.get('#')
      .then(res => {
        console.log(res);
        this.setState({
          output23:
            <span> ➝ {JSON.stringify(res.data.ip).replace(/"/g, '')} ({JSON.stringify(res.data.country).replace(/"/g, '')})</span>
        });
      })
      .catch(err => {
        this.setState({
          output23: "⚠️ Errore!"
        })
      })
  }

  handleChangeIpLookup = event => {
    this.setState({ ipLookup: event.target.value });
  }

  handleSubmitIpLookup = async event => {
    event.preventDefault();
    await apiNinjas.get(`iplookup?address=${this.state.ipLookup}`)
      .then(res => {
        console.log(res);
        this.setState({
          output24:
            <>
              <p><b>IP:</b> {JSON.stringify(res.data.address).replace(/"/g, '')}</p>
              <p><b>È un indirizzo IP valido? </b>{JSON.stringify(res.data.is_valid === true ? "Sì! ✔️" : "No! ❌").replace(/"/g, '')}</p>
              <p><b>Paese: </b>{JSON.stringify(res.data.country).replace(/"/g, '')} ({JSON.stringify(res.data.country_code).replace(/"/g, '')})</p>
              <p><b>Regione: </b>{JSON.stringify(res.data.region).replace(/"/g, '')} ({JSON.stringify(res.data.region_code).replace(/"/g, '')})</p>
              <p><b>Città: </b>{JSON.stringify(res.data.city).replace(/"/g, '')}</p>
              <p><b>CAP: </b>{JSON.stringify(res.data.zip).replace(/"/g, '')}</p>
              <p><b>Latitudine: </b>{JSON.stringify(res.data.lat)}</p>
              <p><b>Longitudine: </b>{JSON.stringify(res.data.lon)}</p>
              <p><b>Fuso orario: </b>{JSON.stringify(res.data.timezone).replace(/"/g, '')}</p>
              <p><b>ISP: </b>{JSON.stringify(res.data.isp).replace(/"/g, '')}</p>
            </>
        });
      })
      .catch(err => {
        this.setState({
          output24: "⚠️ Parametro dell'indirizzo non formattato correttamente!"
        })
      })
  }

  handleConversioneValutaHo = event => {
    this.setState({ conversioneValutaHo: event.target.value });
  }

  handleConversioneValutaVoglio = event => {
    this.setState({ conversioneValutaVoglio: event.target.value });
  }

  handleConversioneValutaQuantita = event => {
    this.setState({ conversioneValutaQuantita: event.target.value });
  }

  handleSubmitConversioneValuta = async event => {
    event.preventDefault();
    await apiNinjas.get(`convertcurrency?have=${this.state.conversioneValutaHo}&want=${this.state.conversioneValutaVoglio}&amount=${this.state.conversioneValutaQuantita}`)
      .then(res => {
        console.log(res);
        this.setState({
          output25:
            <>
              <p>{JSON.stringify(res.data.old_amount).replace(/"/g, '')} {JSON.stringify(res.data.old_currency).replace(/"/g, '')} = {JSON.stringify(res.data.new_amount).replace(/"/g, '')} {JSON.stringify(res.data.new_currency).replace(/"/g, '')}</p>
            </>
        });
      })
      .catch(err => {
        this.setState({
          output25: "⚠️ Inserisci correttamente i codici delle valute e la quantità!"
        })
      })
  }

  handleGeocodificaInversaLat = event => {
    this.setState({ geocodificaInversaLat: event.target.value });
  }

  handleGeocodificaInversaLon = event => {
    this.setState({ geocodificaInversaLon: event.target.value });
  }

  handleSubmitGeocodificaInversa = async event => {
    event.preventDefault();
    await apiNinjas.get(`reversegeocoding?lat=${this.state.geocodificaInversaLat}&lon=${this.state.geocodificaInversaLon}`)
      .then(res => {
        console.log(res);
        this.setState({
          output26: res.data.map(element =>
            <>
              <p><b>Città:</b> {JSON.stringify(element.name).replace(/"/g, '')}</p>
              <p><b>Paese:</b> {JSON.stringify(element.country).replace(/"/g, '')}</p>
              <p><b>Regione o Stato:</b> {JSON.stringify(element.state).replace(/"/g, '')}</p>
              <br />
            </>
          )
        });
      })
      .catch(err => {
        this.setState({
          output26: "⚠️ Inserisci correttamente le coordinate geografiche!"
        })
      })
  }

  handleGeocodificaDirettaCitta = event => {
    this.setState({ geocodificaDirettaCitta: event.target.value });
  }

  handleSubmitGeocodificaDiretta = async event => {
    event.preventDefault();
    await apiNinjas.get(`geocoding?city=${this.state.geocodificaDirettaCitta}`)
      .then(res => {
        console.log(res);
        this.setState({
          output26: res.data.map(element =>
            <>
              <p><b>Città:</b> {JSON.stringify(element.name).replace(/"/g, '')}</p>
              <p><b>Paese:</b> {JSON.stringify(element.country).replace(/"/g, '')}</p>
              <p><b>Latitudine:</b> {JSON.stringify(element.latitude).replace(/"/g, '')}</p>
              <p><b>Longitudine:</b> {JSON.stringify(element.longitude).replace(/"/g, '')}</p>
              <br />
            </>
          )
        });
      })
      .catch(err => {
        this.setState({
          output26: "⚠️ Inserisci correttamente il nome della città!"
        })
      })
  }

  handleInflazionePaese = event => {
    this.setState({ inflazionePaese: event.target.value });
  }

  handleInflazioneTipo = event => {
    this.setState({ inflazioneTipo: event.target.value });
  }

  handleSubmitInflazione = async event => {
    event.preventDefault();
    await apiNinjas.get(`inflation?country=${this.state.inflazionePaese}&type=${this.state.inflazioneTipo === undefined ? this.state.inflazioneTipo = 'CPI' : this.state.inflazioneTipo}`)
      .then(res => {
        console.log(res);
        this.setState({
          output27:
            <>
              <p><b>Paese:</b> {res.data[0].country}</p>
              <p><b>Indice:</b> {res.data[0].type}</p>
              <p><b>Periodo:</b> {res.data[0].period}</p>
              <p><b>Tasso mensile:</b> {res.data[0].monthly_rate_pct}%</p>
              <p><b>Tasso annuale:</b> {res.data[0].yearly_rate_pct}%</p>
            </>
        });
      })
      .catch(err => {
        this.setState({
          output27: "⚠️ Valori non inseriti correttamente o risultato inesistente!"
        })
      })
  }

  handleChangeImmaginiCasualiCategoria = event => {
    this.setState({ immaginiCasualiCategoria: event.target.value });
  }

  handleChangeImmaginiCasualiLarghezza = event => {
    this.setState({ immaginiCasualiLarghezza: event.target.value });
  }

  handleChangeImmaginiCasualiAltezza = event => {
    this.setState({ immaginiCasualiAltezza: event.target.value });
  }

  handleSubmitImmaginiCasuali = async event => {
    event.preventDefault();
    await apiNinjas.get(`randomimage?category=${this.state.immaginiCasualiCategoria === undefined ? this.state.immaginiCasualiCategoria = 'nature' : this.state.immaginiCasualiCategoria}&width=${this.state.immaginiCasualiLarghezza === undefined ? this.state.immaginiCasualiLarghezza = 640 : this.state.immaginiCasualiLarghezza}&heigth=${this.state.immaginiCasualiAltezza === undefined ? this.state.immaginiCasualiAltezza = 480 : this.state.immaginiCasualiAltezza}`)
      .then(res => {
        console.log(res);
        this.setState({
          output28:

            <><img height={this.state.immaginiCasualiAltezza} width={this.state.immaginiCasualiLarghezza} src={`data:image/jpeg;base64,${res.data}`} style={{ paddingBottom: "5px" }} /><br /><a href={`data:image/jpeg;base64,${res.data}`} download={`${Date.now()}.jpeg`}>Scarica l'immagine ({`${Date.now()}.jpeg`}) ⏬</a></>

        });
      });
    // documentazione: https://api-ninjas.com/api/randomimage
  }

  handleChangePaesi = event => {
    this.setState({ paesi: event.target.value });
  }

  handleSubmitPaesi = async event => {
    event.preventDefault();
    await apiNinjas.get(`country?name=${this.state.paesi}`)
      .then(res => {
        console.log(res);
        this.setState({
          output30: res.data.map(element =>
            <>
              <img src={`https://www.countryflagicons.com/FLAT/64/${JSON.stringify(element.iso2).replace(/"/g, '').toUpperCase()}.png`} />
              <p><b>Nome (inglese) del paese:</b> {JSON.stringify(element.name).replace(/"/g, '')}</p>
              <p><b>Codice "ISO 3166-1 alpha-2" del paese:</b> {JSON.stringify(element.iso2).replace(/"/g, '')}</p>
              <p><b>Continente:</b> {JSON.stringify(element.region).replace(/"/g, '')}</p>
              <p><b>Capitale:</b> {JSON.stringify(element.capital).replace(/"/g, '')}</p>
              <p><b>Nome (inglese) della valuta:</b> {JSON.stringify(element.currency.name).replace(/"/g, '')}</p>
              <p><b>Codice della valuta:</b> {JSON.stringify(element.currency.code).replace(/"/g, '')}</p>
              <p><b>Popolazione urbana:</b> {JSON.stringify(element.urban_population).replace(/"/g, '')}%</p>
              <p><b>Indice di mascolinità:</b> {JSON.stringify(element.sex_ratio).replace(/"/g, '')} maschi ogni 100 femmine</p>
            </>),
        });
      })
      .catch(err => {
        this.setState({
          output30: "⚠️ Impossibile trovare il paese!"
        })
      })
    // documentazione: https://api-ninjas.com/api/country
  }

  handleChangeLinguaTesto = event => {
    this.setState({ linguaTesto: event.target.value });
  }

  handleSubmitLinguaTesto = async event => {
    event.preventDefault();
    await apiNinjas.get(`textlanguage?text=${this.state.linguaTesto}`)
      .then(res => {
        console.log(res);
        this.setState({
          output31: <>
            <p><b>Nome (inglese) della lingua:</b> {JSON.stringify(res.data.language).replace(/"/g, '')}</p>
            <p><b>Codice ISO della lingua:</b> {JSON.stringify(res.data.iso).replace(/"/g, '')}</p>
          </>,
        });
      })
      .catch(err => {
        this.setState({
          output31: "⚠️ Impossibile trovare la lingua!"
        })
      })
    // documentazione: https://api-ninjas.com/api/textlanguage
  }

  handleChangeAeroporti = event => {
    this.setState({ aeroporti: event.target.value });
  }

  handleSubmitAeroporti = async event => {
    event.preventDefault();
    await apiNinjas.get(`airports?name=${this.state.aeroporti}`)
      .then(res => {
        console.log(res);
        this.setState({
          output32: res.data.map(element =>
            <>
              <p><b>ICAO:</b> {JSON.stringify(element.icao).replace(/"/g, '')}</p>
              <p><b>IATA:</b> {JSON.stringify(element.iata).replace(/"/g, '')}</p>
              <p><b>Nome (inglese) dell'aeroporto:</b> {JSON.stringify(element.name).replace(/"/g, '')}</p>
              <p><b>Città:</b> {JSON.stringify(element.city).replace(/"/g, '')}</p>
              <p><b>Paese:</b> {JSON.stringify(element.country).replace(/"/g, '')}</p>
              <p><b>Regione o Stato:</b> {JSON.stringify(element.region).replace(/"/g, '')}</p>
              <p><b>Altitudine:</b> {Math.round(JSON.stringify(element.elevation_ft).replace(/"/g, '') * 0.3048)} metri</p>
              <p><b>Latitudine:</b> {JSON.stringify(element.latitude).replace(/"/g, '')}</p>
              <p><b>Longitudine:</b> {JSON.stringify(element.longitude).replace(/"/g, '')}</p>
              <p><b>Fuso orario:</b> {JSON.stringify(element.timezone).replace(/"/g, '')}</p>
              <br />
            </>
          )
        });
      })
      .catch(err => {
        this.setState({
          output32: "⚠️ Errore! Parametri non validi."
        })
      })
  }

  handleChangeAltitudineLatitudine = event => {
    this.setState({ altitudineLatitudine: event.target.value });
  }

  handleChangeAltitudineLongitudine = event => {
    this.setState({ altitudineLongitudine: event.target.value });
  }

  handleSubmitAltitudine = async event => {
    event.preventDefault();
    await apiAltitudine.get(`elevation?latitude=${this.state.altitudineLatitudine}&longitude=${this.state.altitudineLongitudine}`)
      .then(res => {
        console.log(res);
        this.setState({
          output33: <>
            <p><b>Latitudine:</b> {this.state.altitudineLatitudine}</p>
            <p><b>Longitudine:</b> {this.state.altitudineLongitudine}</p>
            <p><b>Altitudine:</b> {JSON.stringify(res.data.elevation[0])} metri</p>
          </>,
        });
      })
      .catch(err => {
        this.setState({
          output33:
            <Typed
              strings={["⚠️ Impossibile trovare l'altitudine!"]}
              typeSpeed={50}
              showCursor={false}
            // React typing animation effect: https://dev.to/techfortified/react-typing-animation-effect-4gk --- https://mattboldt.github.io/typed.js/docs/ --- http://ssbeefeater.github.io/react-typed/?knob-shuffle=false&knob-cursorChar=%7C&knob-backDelay=1&knob-fadeOutDelay=100&knob-backspeed=20&knob-showCursor=true&knob-Strings%5B0%5D=welcome%20to%20react-typed&knob-Strings%5B1%5D=This%20is%20a%20react%20component%20that%20wraps%20up%20the%20%3Ca%20href%3D%22https%3A%2F%2Fgithub.com%2Fmattboldt%2Ftyped.js%2F%22%3Etyped.js%3C%2Fa%3E&knob-Strings%5B2%5D=If%20you%20like%20the%20project%20add%20a%20star%20in%20%3Ca%20href%3D%22https%3A%2F%2Fgithub.com%2Fmattboldt%2Ftyped.js%2F%22%3Etyped.js%3C%2Fa%3E%20and%20%3Ca%20href%3D%22https%3A%2F%2Fgithub.com%2Fmattboldt%2Ftyped.js%2F%22%3Ereact-typed%3C%2Fa%3E&knob-smartBackspace=true&knob-loopCount=0&knob-type%20speed=50&knob-fadeOut=false&knob-loop=true&selectedKind=react-typed&selectedStory=Basic%20Example&full=0&down=1&left=1&panelRight=0&downPanel=storybooks%2Fstorybook-addon-knobs
            />
        })
      })
    // documentazione: https://open-meteo.com/en/docs/elevation-api
  }
  handleChangeLogoAziende = event => {
    this.setState({ logoAziende: event.target.value });
  }

  handleSubmitLogoAziende = async event => {
    event.preventDefault();
    await apiNinjas.get(`logo?name=${this.state.logoAziende}`)
      .then(res => {
        console.log(res);
        console.log(res.data.image)

        this.setState({
          output34: res.data.map(element =>
            <>
            <br /> <br />
              <p><b>Nome dell'azienda:</b> {JSON.stringify(element.name).replace(/"/g, '')}</p>
              <p><b>Simbolo di borsa:</b> {JSON.stringify(element.ticker).replace(/"/g, '')}</p>
              <br />
              <img src={`${element.image}`}  width="480" height="auto" /><br />
              <a href={`${element.image}`} target="_blank" >Vai all'immagine!🔗</a>
              <br /> <br />
            </>
          )

        });
      })
      .catch(err => {
        this.setState({
          output34:
            <Typed
              strings={['⚠️ Impossibile trovare il logo!']}
              typeSpeed={50}
              showCursor={false}
            // React typing animation effect: https://dev.to/techfortified/react-typing-animation-effect-4gk --- https://mattboldt.github.io/typed.js/docs/ --- http://ssbeefeater.github.io/react-typed/?knob-shuffle=false&knob-cursorChar=%7C&knob-backDelay=1&knob-fadeOutDelay=100&knob-backspeed=20&knob-showCursor=true&knob-Strings%5B0%5D=welcome%20to%20react-typed&knob-Strings%5B1%5D=This%20is%20a%20react%20component%20that%20wraps%20up%20the%20%3Ca%20href%3D%22https%3A%2F%2Fgithub.com%2Fmattboldt%2Ftyped.js%2F%22%3Etyped.js%3C%2Fa%3E&knob-Strings%5B2%5D=If%20you%20like%20the%20project%20add%20a%20star%20in%20%3Ca%20href%3D%22https%3A%2F%2Fgithub.com%2Fmattboldt%2Ftyped.js%2F%22%3Etyped.js%3C%2Fa%3E%20and%20%3Ca%20href%3D%22https%3A%2F%2Fgithub.com%2Fmattboldt%2Ftyped.js%2F%22%3Ereact-typed%3C%2Fa%3E&knob-smartBackspace=true&knob-loopCount=0&knob-type%20speed=50&knob-fadeOut=false&knob-loop=true&selectedKind=react-typed&selectedStory=Basic%20Example&full=0&down=1&left=1&panelRight=0&downPanel=storybooks%2Fstorybook-addon-knobs
            />
        })
      })
    // documentazione: https://open-meteo.com/en/docs/elevation-api
  }

  componentDidMount() {

  }

  componentWillMount() {
    this.callAPI();
  }

  
  render() {
    const { location } = this.props;

    return (
      <ThemeProvider
        breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
        minBreakpoint="xxs"
      >
        <MatrixBackground />
        <Layout location={location}>
          <Container fluid>
            <div css={sharedStyles.articleLayout.container}>
              <div css={sharedStyles.articleLayout.content}>
                <TitleAndMetaTags
                  canonicalUrl={`${urlRoot}/funzioni.html`}
                  title="Milani - Funzioni"
                />

                <Row>

                  <Col style={{ width: 'auto', position: 'fixed', margin: '8rem 1rem', padding: '1rem', backgroundColor: 'trasparent', color: '#025b6d' }}>
                    <h2 className="funzioni-menu-item" style={{ marginTop: '0px', paddingBottom: '10px', color: '#024957' }}><a href="#" style={{ fontWeight: 'bold' }}>
                      <Typed
                        strings={[' ƒ Funzioni ']}
                        typeSpeed={30}
                        showCursor={false}
                      // React typing animation effect: https://dev.to/techfortified/react-typing-animation-effect-4gk --- https://mattboldt.github.io/typed.js/docs/ --- http://ssbeefeater.github.io/react-typed/?knob-shuffle=false&knob-cursorChar=%7C&knob-backDelay=1&knob-fadeOutDelay=100&knob-backspeed=20&knob-showCursor=true&knob-Strings%5B0%5D=welcome%20to%20react-typed&knob-Strings%5B1%5D=This%20is%20a%20react%20component%20that%20wraps%20up%20the%20%3Ca%20href%3D%22https%3A%2F%2Fgithub.com%2Fmattboldt%2Ftyped.js%2F%22%3Etyped.js%3C%2Fa%3E&knob-Strings%5B2%5D=If%20you%20like%20the%20project%20add%20a%20star%20in%20%3Ca%20href%3D%22https%3A%2F%2Fgithub.com%2Fmattboldt%2Ftyped.js%2F%22%3Etyped.js%3C%2Fa%3E%20and%20%3Ca%20href%3D%22https%3A%2F%2Fgithub.com%2Fmattboldt%2Ftyped.js%2F%22%3Ereact-typed%3C%2Fa%3E&knob-smartBackspace=true&knob-loopCount=0&knob-type%20speed=50&knob-fadeOut=false&knob-loop=true&selectedKind=react-typed&selectedStory=Basic%20Example&full=0&down=1&left=1&panelRight=0&downPanel=storybooks%2Fstorybook-addon-knobs
                      />
                      &nbsp;<VisitFunzioni /></a></h2>
                    <ul>
                      <li><a className="funzioni-menu-item" href="#corano">➝ Corano</a></li>
                      <li><a className="funzioni-menu-item" href="#mostrepeatedletter">➝ Most Repeated Letter</a></li>
                      <li><a className="funzioni-menu-item" href="#caesarcipher" style={{ textDecoration: 'none' }}>➝ Caesar Cipher</a></li>
                      <li><a className="funzioni-menu-item" href="#spellinteger" style={{ textDecoration: 'none' }}>➝ Spell Integer</a></li>
                      <li><a className="funzioni-menu-item" href="#removeemoji" style={{ textDecoration: 'none' }}>➝ Remove Emoji</a></li>
                      <li><a className="funzioni-menu-item" href="#removeaccent" style={{ textDecoration: 'none' }}>➝ Remove Accent</a></li>
                      <li><a className="funzioni-menu-item" href="#celsiusfahrenheitkelvin" style={{ textDecoration: 'none' }}>➝ Celsius Fahrenheit Kelvin</a></li>
                      <li><a className="funzioni-menu-item" href="#testpalindrome" style={{ textDecoration: 'none' }}>➝ Test Palindrome</a></li>
                      <li><a className="funzioni-menu-item" href="#randopass" style={{ textDecoration: 'none' }}>➝ Rando Pass</a></li>
                      <li><a className="funzioni-menu-item" href="#abjad" style={{ textDecoration: 'none' }}>➝ Abjad</a></li>
                      <li><a className="funzioni-menu-item" href="#fibonacci" style={{ textDecoration: 'none' }}>➝ Fibonacci</a></li>
                      <li><a className="funzioni-menu-item" href="#arabictoroman" style={{ textDecoration: 'none' }}>➝ Arabic to Roman</a></li>
                      <li><a className="funzioni-menu-item" href="#meteo">➝ Meteo</a></li>
                      <li><a className="funzioni-menu-item" href="#matematica">➝ Matematica</a></li>
                      <li><a className="funzioni-menu-item" href="#inglese">➝ Inglese</a></li>
                      <li><a className="funzioni-menu-item" href="#geolocalizzazione-ip">➝ Geolocalizzazione IP</a></li>
                      <li><a className="funzioni-menu-item" href="#dns-lookup">➝ DNS Lookup</a></li>
                      <li><a className="funzioni-menu-item" href="#qualita-aria">➝ Qualità dell'aria</a></li>
                      <li><a className="funzioni-menu-item" href="#qr-code">➝ Generatore di codici QR</a></li>
                      <li><a className="funzioni-menu-item" href="#convalida-telefono">➝ Convalida Telefono</a></li>
                      <li><a className="funzioni-menu-item" href="#numero-telaio">➝ Numero di telaio</a></li>
                      <li><a className="funzioni-menu-item" href="#ora-mondiale">➝ Ora mondiale</a></li>
                      <li><a className="funzioni-menu-item" href="#citta">➝ Città del mondo</a></li>
                      <li><a className="funzioni-menu-item" href="#monitoraggio-ip">➝ Monitoraggio IP</a></li>
                      <li><a className="funzioni-menu-item" href="#conversione-valuta">➝ Conversione di valuta</a></li>
                      <li><a className="funzioni-menu-item" href="#geocodifica-inversa">➝ Geocodifica inversa</a></li>
                      <li><a className="funzioni-menu-item" href="#inflazione">➝ Inflazione</a></li>
                      <li><a className="funzioni-menu-item" href="#immagini-casuali">➝ Immagini casuali</a></li>
                      <li><a className="funzioni-menu-item" href="#statistiche-covid">➝ Statistiche Covid</a></li>
                      <li><a className="funzioni-menu-item" href="#paesi">➝ Paesi del mondo</a></li>
                      <li><a className="funzioni-menu-item" href="#lingua-testo">➝ Lingua del testo</a></li>
                      <li><a className="funzioni-menu-item" href="#aeroporti">➝ Aeroporti</a></li>
                      <li><a className="funzioni-menu-item" href="#altitudine">➝ Altitudine</a></li>
                      <li><a className="funzioni-menu-item" href="#logo-aziende">➝ Logo aziende</a></li>
                    </ul>
                  </Col>

                  <Col style={{ width: '80%', marginLeft: '18rem' }}>

                    <h3 className="anchorbug" id="corano" style={{ marginTop: '-160px', color: '#024957' }}>Corano</h3>
                    <div style={{ marginBottom: '-114px' }}>
                      <p>Questa funzione trova il testo di un versetto coranico. Ti permette anche di fare istikhara.</p>
                      <div className="input-group mb-3">
                        <form onSubmit={this.handleSubmitCorano}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <input type="text" name="title" onChange={this.handleChangeSura} className="form-control" placeholder="sura" />
                          <input type="text" name="title" onChange={this.handleChangeVersetto} className="form-control" placeholder="versetto" />
                          <button onClick={() => this.setState({ output: `` }) & this.setState({ outputquranabjad: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                          {/* <button onClick={() => copy(`${this.state.output}`)} className="btn btn-primary" type="button" id="button-addon1">Copia</button> */}
                          <button onClick={this.handleIstikhara} className="btn btn-success" id="button-addon1">Istikhara</button>
                          {/* <button onClick={() => this.setState({ outputquranabjad: `${abjad(this.state.output)}` })} className="btn btn-danger" type="button" id="button-addon1">Abjad</button> */}
                          {/* <button onClick={() => print(document.querySelector('#importurl'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button> */}
                        </form>
                      </div>
                      <p className="fnoutput" id="corano" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output}</p>
                      <p className="fnoutputquranabjad" id="abjadcorano" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.outputquranabjad}</p>
                    </div>

                    <h3 className="anchorbug" id="mostrepeatedletter" style={{ color: '#024957' }}>Most Repeated Letter</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione trova la (prima) lettera più ripetuta in una stringa.</p>
                      <div className="input-group mb-3">
                        <button onClick={() => this.setState({ output1: `La (prima) lettera più ripetuta della stringa "${this.inputmostrepeatedletter.value}" è "${mostrepeatedletter(this.inputmostrepeatedletter.value)}".` })} className="btn btn-success" type="button" id="button-addon1">Esegui</button>
                        <input
                          ref={input => { this.inputmostrepeatedletter = input; }}
                          className="form-control"
                          placeholder=""
                          aria-label=" . . ."
                          defaultValue=""
                        />
                        <button onClick={() => this.setState({ output1: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                        <button onClick={() => copy(`${mostrepeatedletter(this.inputmostrepeatedletter.value)}`)} className="btn btn-primary" type="button" id="button-addon1">Copia</button>
                      </div>
                      <p className="fnoutput1" style={{ height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output1}</p>
                      {/* <button onClick={() => print(document.querySelector('.fnoutput1'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button> */}
                    </div>

                    <h3 style={{ color: '#024957' }} className="anchorbug" id="caesarcipher">Caesar Cipher</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione applica il Cifrario di Cesare, con chiave 0 ≤ n ≤ 26, a una stringa.</p>
                      <div className="input-group mb-3">
                        <button onClick={() => this.setState({ output2: `La stringa "${this.inputcaesarcipherstr.value}" convertita col Cifrario di Cesare con chiave "${this.inputcaesarciphern.value}" diventa "${caesarcipher(this.inputcaesarcipherstr.value, this.inputcaesarciphern.value)}".` })} className="btn btn-success" type="button" id="button-addon1">Esegui</button>
                        <input type="text"
                          ref={input => { this.inputcaesarcipherstr = input; }}
                          className="form-control"
                          placeholder="stringa"
                          aria-label=" . . ."
                          defaultValue=""
                        />
                        <input type="text"
                          ref={input => { this.inputcaesarciphern = input; }}
                          className="form-control"
                          placeholder="chiave"
                          aria-label=" . . ."
                          defaultValue=""
                        />
                        <button onClick={() => this.setState({ output2: `` })} className="btn btn-danger" type="button" id="button-addon1"><p>Cancella</p></button>
                        <button onClick={() => copy(`${caesarcipher(this.inputcaesarcipherstr.value, this.inputcaesarciphern.value)}`)} className="btn btn-primary" type="button" id="button-addon1">Copia</button>
                      </div>
                      <p className="fnoutput2" style={{ height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output2}</p>
                      {/* <button onClick={() => print(document.querySelector('.fnoutput2'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button> */}
                    </div>

                    <h3 style={{ color: '#024957' }} className="anchorbug" id="spellinteger">Spell Integer</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione converte un numero intero positivo nel suo corrispondente letterale.</p>
                      <div className="input-group mb-3">
                        <button onClick={() => this.setState({ output3: `Il corrispondente letterale della stringa "${this.inputspellinteger.value}" è "${spellinteger(this.inputspellinteger.value)}".` })
                        } className="btn btn-success" type="button" id="button-addon1">Esegui</button>
                        <input type="text"
                          ref={input => { this.inputspellinteger = input; }}
                          className="form-control"
                          placeholder=""
                          aria-label=" . . ."
                          defaultValue=""
                        />
                        <button onClick={() => this.setState({ output3: `` })} className="btn btn-danger" type="button" id="button-addon1"><p>Cancella</p></button>
                        <button onClick={() => copy(`${spellinteger(this.inputspellinteger.value)}`)} className="btn btn-primary" type="button" id="button-addon1">Copia</button>
                      </div >
                      <p className="fnoutput3" style={{ height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output3}</p>
                      {/* <button onClick={() => print(document.querySelector('.fnoutput3'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button> */}
                    </div>

                    <h3 style={{ color: '#024957' }} className="anchorbug" id="removeemoji">Remove Emoji</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione rimuove gli emoji da una stringa.</p>
                      <div className="input-group mb-3">
                        <button onClick={() => this.setState({ output4: `La stringa "${this.inputremoveemoji.value}" senza emoji è "${removeemoji(this.inputremoveemoji.value)}".` })} className="btn btn-success" type="button" id="button-addon1">Esegui</button>
                        <input type="text"
                          ref={input => { this.inputremoveemoji = input; }}
                          className="form-control"
                          placeholder=""
                          aria-label=" . . ."
                          defaultValue=""
                        />
                        <button onClick={() => this.setState({ output4: `` })} className="btn btn-danger" type="button" id="button-addon1"><p>Cancella</p></button>
                        <button onClick={() => copy(`${removeemoji(this.inputremoveemoji.value)}`)} className="btn btn-primary" type="button" id="button-addon1">Copia</button>
                      </div>
                      <p className="fnoutput4" style={{ height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output4}</p>
                      {/* <button onClick={() => print(document.querySelector('.fnoutput4'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button> */}
                    </div>

                    <h3 style={{ color: '#024957' }} className="anchorbug" id="removeaccent">Remove Accent</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione rimuove gli accenti dalle lettere di una stringa.</p>
                      <div className="input-group mb-3">
                        <button onClick={() => this.setState({ output5: `La stringa "${this.inputremoveaccent.value}" senza accenti sulle lettere è "${removeaccent(this.inputremoveaccent.value)}".` })} className="btn btn-success" type="button" id="button-addon1">Esegui</button>
                        <input type="text"
                          ref={input => { this.inputremoveaccent = input; }}
                          className="form-control"
                          placeholder=""
                          aria-label=" . . ."
                          defaultValue=""
                        />
                        <button onClick={() => this.setState({ output5: `` })} className="btn btn-danger" type="button" id="button-addon1"><p>Cancella</p></button>
                        <button onClick={() => copy(`${removeaccent(this.inputremoveaccent.value)}`)} className="btn btn-primary" type="button" id="button-addon1">Copia</button>
                      </div>
                      <p className="fnoutput5" style={{ height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output5}</p>
                      {/* <button onClick={() => print(document.querySelector('.fnoutput5'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button> */}
                    </div>

                    <h3 style={{ color: '#024957' }} className="anchorbug" id="celsiusfahrenheitkelvin">Celsius Fahrenheit Kelvin</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione converte la temperatura tra gradi celsius, fahrenheit e kelvin.<br />
                        Inserire la temperatura da convertire facendola seguire da unità di misura (c, f o k)</p>
                      <div className="input-group mb-3">
                        <button onClick={() => this.setState({ output6: `"${this.inputcelsiusfahrenheitkelvin.value}" è pari a "${celsiusfahrenheitkelvin(this.inputcelsiusfahrenheitkelvin.value)}".` })} className="btn btn-success" type="button" id="button-addon1">Esegui</button>
                        <input type="text"
                          ref={input => { this.inputcelsiusfahrenheitkelvin = input; }}
                          className="form-control"
                          placeholder="Inserire la temperatura"
                          aria-label=" . . ."
                          defaultValue=""
                        />
                        <button onClick={() => this.setState({ output6: `` })} className="btn btn-danger" type="button" id="button-addon1"><p>Cancella</p></button>
                        <button onClick={() => copy(`${celsiusfahrenheitkelvin(this.inputcelsiusfahrenheitkelvin.value)}`)} className="btn btn-primary" type="button" id="button-addon1">Copia</button>
                      </div>
                      <p className="fnoutput6" style={{ height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output6}</p>
                      {/* <button onClick={() => print(document.querySelector('.fnoutput6'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button> */}
                    </div>

                    <h3 style={{ color: '#024957' }} className="anchorbug" id="testpalindrome">Test Palindrome</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione verifica se una stringa è un palindromo oppure no.</p>
                      <div className="input-group mb-3">
                        <button onClick={() => this.setState({ output7: `La stringa "${this.inputtestpalindrome.value}" ${testpalindrome(this.inputtestpalindrome.value) ? 'è un palindromo.' : 'non è un palindromo.'}` })} className="btn btn-success" type="button" id="button-addon1">Esegui</button>
                        <input type="text"
                          ref={input => { this.inputtestpalindrome = input; }}
                          className="form-control"
                          placeholder=""
                          aria-label=" . . ."
                          defaultValue=""
                        />
                        <button onClick={() => this.setState({ output7: `` })} className="btn btn-danger" type="button" id="button-addon1"><p>Cancella</p></button>
                        <button onClick={() => copy(`${testpalindrome(this.inputtestpalindrome.value) ? 'È un palindromo.' : 'Non è un palindromo.'}`)} className="btn btn-primary" type="button" id="button-addon1">Copia</button>
                      </div>
                      <p className="fnoutput7" style={{ height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output7}</p>
                      {/* <button onClick={() => print(document.querySelector('.fnoutput7'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button> */}
                    </div>

                    <h3 style={{ color: '#024957' }} className="anchorbug" id="randopass">Rando Pass</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione genera una random password di lunghezza 'n'.</p>
                      <div className="input-group mb-3">
                        <button onClick={() => this.setState({ output8: `${randopass(this.inputrandopass.value)}` })} className="btn btn-success" type="button" id="button-addon1">Esegui</button>
                        <input type="text"
                          ref={input => { this.inputrandopass = input; }}
                          className="form-control"
                          placeholder="Inserisci 'n'"
                          aria-label=" . . ."
                          defaultValue=""
                        />
                        <button onClick={() => this.setState({ output8: `` })} className="btn btn-danger" type="button" id="button-addon1"><p>Cancella</p></button>
                        <button onClick={() => copy(`${this.state.output8}`)} className="btn btn-primary" type="button" id="button-addon1">Copia</button>
                      </div>
                      <p className="fnoutput8" style={{ height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output8}</p>
                      {/* <button onClick={() => print(document.querySelector('.fnoutput8'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button> */}
                    </div>

                    <h3 style={{ color: '#024957' }} className="anchorbug" id="abjad">Abjad</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione calcola l'abjad di una stringa.</p>
                      <div className="input-group mb-3">
                        <button onClick={() => this.setState({ output9: `L'abjad di "${this.inputabjad.value}" è uguale a ${abjad(this.inputabjad.value)}.` })} className="btn btn-success" type="button" id="button-addon1">Esegui</button>
                        <input type="text"
                          ref={input => { this.inputabjad = input; }}
                          className="form-control"
                          placeholder=""
                          aria-label=" . . ."
                          defaultValue=""
                        />
                        <button onClick={() => this.setState({ output9: null })} className="btn btn-danger" type="button" id="button-addon1"><p>Cancella</p></button>
                        <button onClick={() => copy(`${abjad(this.inputabjad.value)}`)} className="btn btn-primary" type="button" id="button-addon1">Copia</button>
                      </div>
                      <p className="fnoutput9" style={{ height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output9}</p>
                      {/* <button onClick={() => print(document.querySelector('.fnoutput9'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button> */}
                    </div>

                    <h3 style={{ color: '#024957' }} className="anchorbug" id="fibonacci">Fibonacci</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione calcola il fibonacci di un numero intero positivo.</p>
                      <div className="input-group mb-3">
                        <button onClick={() => this.setState({ output10: `Il fibonacci di "${this.inputfibonacci.value}" è uguale a ${fibonacci(this.inputfibonacci.value)}.` })} className="btn btn-success" type="button" id="button-addon1">Esegui</button>
                        <input type="text"
                          ref={input => { this.inputfibonacci = input; }}
                          className="form-control"
                          placeholder="Inserisci il numero"
                          aria-label=" . . ."
                          defaultValue=""
                        />
                        <button onClick={() => this.setState({ output10: null })} className="btn btn-danger" type="button" id="button-addon1"><p>Cancella</p></button>
                        <button onClick={() => copy(`${fibonacci(this.inputfibonacci.value)}`)} className="btn btn-primary" type="button" id="button-addon1">Copia</button>
                      </div>
                      <p className="fnoutput10" style={{ height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output10}</p>
                      {/* <button onClick={() => print(document.querySelector('.fnoutput10'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button> */}
                    </div>

                    <h3 style={{ color: '#024957' }} className="anchorbug" id="arabictoroman">Arabic to Roman</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione calcola l'equivalente romano di un numero.</p>
                      <div className="input-group mb-3">
                        <button onClick={() => this.setState({ output11: `L'equivalente romano di "${this.inputarabictoroman.value}" è "${arabictoroman(this.inputarabictoroman.value)}".` })} className="btn btn-success" type="button" id="button-addon1">Esegui</button>
                        <input type="text"
                          ref={input => { this.inputarabictoroman = input; }}
                          className="form-control"
                          placeholder=""
                          aria-label=" . . ."
                          defaultValue=""
                        />
                        <button onClick={() => this.setState({ output11: '' })} className="btn btn-danger" type="button" id="button-addon1"><p>Cancella</p></button>
                        <button onClick={() => copy(`${arabictoroman(this.inputarabictoroman.value)}`)} className="btn btn-primary" type="button" id="button-addon1">Copia</button>
                      </div>
                      <p className="fnoutput11" style={{ height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output11}</p>
                      {/* <button onClick={() => print(document.querySelector('.fnoutput11'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button> */}
                    </div>

                    <h3 style={{ color: '#024957' }} className="anchorbug" id="meteo">Meteo</h3>
                    <div style={{ marginBottom: '-110px' }}>
                      <p>Questa funzione fornisce le ultime informazioni meteorologiche per qualsiasi città del mondo.</p>
                      <div className="input-group mb-3">
                        <form onSubmit={this.handleSubmitMeteo}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <input type="text" name="title" onChange={this.handleChangeMeteo} className="form-control" placeholder="inserisci una città" />
                          <button onClick={() => this.setState({ output12: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                        </form>
                      </div>
                      <p className="fnoutput12" id="meteo" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output12}</p>
                    </div>

                    <h3 className="anchorbug" id="matematica" style={{ color: '#024957' }}>Matematica</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione esegue operazioni matematiche, dal calcolo numerico all'analisi matematica simbolica.</p>
                      <div>
                        <form onSubmit={this.handleSubmitMatematica}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <select className="select-form-control" name="operazione" id="operazione" onChange={this.handleChangeOperazione}>
                            <option value=""> --- scegli operazione --- </option>
                            <option value="derive">derivata</option>
                            <option value="integrate">integrale</option>
                            <option value="simplify">semplificazione</option>
                            <option value="factor">fattorizzazione</option>
                            <option value="log">logaritmo</option>
                            <option value="abs">valore assoluto</option>
                            <option value="sin">seno</option>
                            <option value="cos">coseno</option>
                            <option value="tan">tangente</option>
                            <option value="arcsin">arcoseno</option>
                            <option value="arccos">arcocoseno</option>
                            <option value="arctan">arcotangente</option>
                            <option value="area">area sotto curva</option>
                            <option value="zeroes">trova zeri funzione</option>
                            <option value="tangent">trova retta tangente</option>
                          </select>
                          <input type="text" name="title" onChange={this.handleChangeEspressione} className="form-control" placeholder="espressione" />
                          <button onClick={() => this.setState({ output13: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                          <button onClick={() => copy(`${this.state.output13}`)} className="btn btn-primary" type="button" id="button-addon1">Copia</button>
                          {/* <button onClick={() => print(document.querySelector('#importurl'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button> */}
                        </form>
                      </div>
                      <p className="fnoutput13" id="matematica" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output13}</p>
                    </div>

                    <h3 style={{ color: '#024957' }} className="anchorbug" id="inglese">Inglese</h3>
                    <div style={{ marginBottom: '-95px' }}>
                      <p>Questa funzione restituisce la definizione, i sinonimi e i contrari, e la pronuncia delle parole inglesi. <br />
                        Per poter riascoltare la pronuncia di una parola o ascoltarne una nuova, <br /> bisogna prima cancellare il risultato della ricerca premendo "Cancella".</p>
                      <div className="input-group mb-3">
                        <form onSubmit={this.handleSubmitInglese}>
                          <button id="button-addon1" onClick={this.handleSubmitDizionarioInglese}>Definizione</button>
                          <input type="text" name="title" onChange={this.handleChangeInglese} className="form-control" placeholder="Inserisci parola inglese" />
                          <button onClick={() => this.setState({ output14: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                          <br />
                          <button id="button-addon1" onClick={this.handleSubmitSinonimiContrariInglese}>Sinonimi/Contrari</button>
                          <button id="button-addon1" onClick={this.handleSubmitParoleInRima}>Parole in rima</button>
                          <button id="button-addon1" type="submit" className="btn btn-success">Pronuncia</button>
                          <br />
                          <button id="button-addon1" onClick={this.handleSubmitParoleCasualiInglesi}>Mostrami una parola inglese a caso</button>
                        </form>
                      </div>
                      <p className="fnoutput14" style={{ height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output14}</p>
                    </div>

                    <h3 className="anchorbug" id="geolocalizzazione-ip" style={{ color: '#024957' }}>Geolocalizzazione IP</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Funzione di geolocalizzazione IP.</p>
                      <div className="input-group mb-3">
                        <form onSubmit={this.handleSubmitGeolocalizzazioneIp}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <input type="text" name="title" onChange={this.handleChangeGeolocalizzazioneIp} className="form-control" placeholder="inserisci ip o dominio" />
                          <button onClick={() => this.setState({ output15: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                          {/* <button onClick={() => copy(`${this.state.output15}`)} className="btn btn-primary" type="button" id="button-addon1">Copia</button> */}
                          {/* <button onClick={() => print(document.querySelector('#importurl'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button> */}
                        </form>
                      </div>
                      <p className="fnoutput15" id="geolocalizzazione-ip" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output15}</p>
                    </div>

                    <h3 className="anchorbug" id="dns-lookup" style={{ color: '#024957' }}>DNS Lookup</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione recupera i record DNS per un determinato dominio.</p>
                      <div className="input-group mb-3">
                        <form onSubmit={this.handleSubmitDnsLookup}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <input type="text" name="title" onChange={this.handleChangeDnsLookupDomain} className="form-control" placeholder="inserisci un dominio" />
                          <button onClick={() => this.setState({ output16: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                        </form>
                      </div>
                      <p className="fnoutput16" id="dns-lookup" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output16}</p>
                    </div>

                    <h3 className="anchorbug" id="qualita-aria" style={{ color: '#024957' }}>Qualità dell'aria</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione fornisce le informazioni più recenti sulla qualità dell'aria per qualsiasi città o località geografica del mondo. <br />
                        Fornisce non solo l'indice di qualità dell'aria (AQI) ma anche le concentrazioni per i principali inquinanti. <br />
                        Più alto è il valore AQI, maggiore è il livello di inquinamento atmosferico e maggiore è la preoccupazione per la salute. <br />
                        Ad esempio, un valore AQI di 50 o inferiore rappresenta una buona qualità dell'aria, <br />
                        mentre un valore AQI superiore a 300 rappresenta una qualità dell'aria pericolosa.</p>
                      <div className="input-group mb-3">
                        <form onSubmit={this.handleSubmitQualitaAria}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <input type="text" name="title" onChange={this.handleChangeQualitaAriaCitta} className="form-control" placeholder="nome della città" />
                          <button onClick={() => this.setState({ output17: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                        </form>
                      </div>
                      <p className="fnoutput17" id="qualita-aria" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output17}</p>
                    </div>

                    <h3 className="anchorbug" id="qr-code" style={{ color: '#024957' }}>Generatore di codici QR</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Generatore di codici QR per qualsiasi contenuto.</p>
                      <div>
                        <form onSubmit={this.handleSubmitQrCode}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <select className="select-form-control" name="formato" id="formato" onChange={this.handleChangeQrCodeFormat}>
                            <option value="png">scegli il formato dell'immagine</option>
                            <option value="png">formato png</option>
                            <option value="jpg">formato jpg</option>
                            <option value="jpeg">formato jpeg</option>
                            {/* <option value="eps">formato eps</option> */}
                            <option value="svg">formato svg</option>
                          </select>
                          <button onClick={() => this.setState({ output18: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                          <br />
                          <input type="text" name="title" onChange={this.handleChangeQrCodeData} className="form-control" placeholder="inserisci un contenuto" />
                          <input type="text" name="title" onChange={this.handleChangeQrCodeSize} className="form-control" placeholder="dimensione" /><br />
                          <input type="text" name="title" onChange={this.handleChangeQrCodeFgColor} className="form-control" placeholder="colore primo piano (hex)" />
                          <input type="text" name="title" onChange={this.handleChangeQrCodeBgColor} className="form-control" placeholder="colore di sfondo (hex)" />
                        </form>
                      </div>
                      <p className="fnoutput18" id="qr-code" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output18}</p>
                    </div>

                    <h3 className="anchorbug" id="convalida-telefono" style={{ color: '#024957' }}>Convalida Telefono</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione consente di verificare se un determinato numero di telefono è valido e di ottenerne i metadati.</p>
                      <div className="input-group mb-3">
                        <form onSubmit={this.handleSubmitConvalidaTelefono}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <input type="text" name="title" onChange={this.handleChangeConvalidaTelefono} className="form-control" placeholder="inserisci un n. di telefono" />
                          <button onClick={() => this.setState({ output19: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                        </form>
                      </div>
                      <p className="fnoutput19" id="convalida-telefono" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output19}</p>
                    </div>

                    <h3 className="anchorbug" id="numero-telaio" style={{ color: '#024957' }}>Numero di Telaio</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione fornisce informazioni su un veicolo decodificando qualsiasi numero di telaio, <br />
                        noto anche come numero di identificazione del veicolo (VIN).</p>
                      <div className="input-group mb-3">
                        <form onSubmit={this.handleSubmitNumeroTelaio}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <input type="text" name="title" onChange={this.handleChangeNumeroTelaio} className="form-control" placeholder="inserisci un n. di telaio" />
                          <button onClick={() => this.setState({ output20: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                        </form>
                      </div>
                      <p className="fnoutput20" id="numero-telaio" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output20}</p>
                    </div>

                    <h3 className="anchorbug" id="ora-mondiale" style={{ color: '#024957' }}>Ora mondiale</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Con questa funzione ottieni la data e l'ora correnti delle principali città del mondo.</p>
                      <div className="input-group mb-3">
                        <form onSubmit={this.handleSubmitOraMondiale}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <input type="text" name="title" onChange={this.handleChangeOraMondiale} className="form-control" placeholder="inserisci una città" />
                          <button onClick={() => this.setState({ output21: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                        </form>
                      </div>
                      <p className="fnoutput21" id="ora-mondiale" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output21}</p>
                    </div>

                    <h3 className="anchorbug" id="citta" style={{ color: '#024957' }}>Città del mondo</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione fornisce informazioni utili su molte città del mondo.</p>
                      <div className="input-group mb-3">
                        <form onSubmit={this.handleSubmitCitta}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <input type="text" name="title" onChange={this.handleChangeCitta} className="form-control" placeholder="nome città (in inglese)" />
                          <button onClick={() => this.setState({ output22: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                        </form>
                      </div>
                      <p className="fnoutput22" id="citta" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output22}</p>
                    </div>

                    <h3 className="anchorbug" id="monitoraggio-ip" style={{ color: '#024957' }}>Monitoraggio IP</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione trova l'indirizzo IP della tua connesione internet. <br />
                        Inserendo invece un valido indirizzo IP (IPv4 o IPv6), puoi conoscere utili informazioni sulla sua posizione.</p>
                      <div className="input-group mb-3">
                        <form onSubmit={this.handleSubmitIpLookup}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Trova un indirizzo di IP&nbsp;</button>
                          <input type="text" name="title" onChange={this.handleChangeIpLookup} className="form-control" placeholder="indirizzo ip" />
                          <button onClick={() => this.setState({ output23: `` }) & this.setState({ output24: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                        </form>
                        <form onSubmit={this.handleSubmitMonitoraggioIp}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Trova il mio indirizzo IP</button><span className="fnoutput23" id="monitoraggio-ip" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output23}</span>
                        </form>
                      </div>
                      <p className="fnoutput24" id="monitoraggio-ip" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output24}</p>
                    </div>

                    <h3 className="anchorbug" id="conversione-valuta" style={{ color: '#024957' }}>Conversione di valuta</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione ti consente di convertire coppie di valute. Scegli tra oltre 100 valute diverse. <br />
                        Attenzione: potrebbe esserci un ritardo massimo di 1 ora nel recupero dei tassi di cambio più recenti. <br />
                        Per conoscere i codici delle monete, clicca <a href="https://publications.europa.eu/code/it/it-5000700.htm" target="_blank" style={{ color: "#30d5c8" }}><b>qui</b></a>.</p>
                      <div className="input-group mb-3">
                        <form onSubmit={this.handleSubmitConversioneValuta}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <input type="text" name="title" onChange={this.handleConversioneValutaQuantita} className="form-control" placeholder="quantita posseduta" />
                          <input type="text" name="title" onChange={this.handleConversioneValutaHo} className="form-control" placeholder="codice valuta posseduta" />
                          <input type="text" name="title" onChange={this.handleConversioneValutaVoglio} className="form-control" placeholder="codice valuta desiderata" />
                          <button onClick={() => this.setState({ output25: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                        </form>
                      </div>
                      <p className="fnoutput25" id="citta" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output25}</p>
                    </div>

                    <h3 className="anchorbug" id="geocodifica-inversa" style={{ color: '#024957' }}>Geocodifica inversa</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>La funzione di geocodifica inversa consente di convertire le coordinate <br /> di latitudine/longitudine in città che rientrano in tali coordinate.</p>
                      <div className="input-group mb-3">
                        <form onSubmit={this.handleSubmitGeocodificaInversa}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <input type="text" name="title" onChange={this.handleGeocodificaInversaLat} className="form-control" placeholder="inserisci la latitudine" />
                          <input type="text" name="title" onChange={this.handleGeocodificaInversaLon} className="form-control" placeholder="inserisci la longitudine" />
                          <button onClick={() => this.setState({ output26: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                        </form>
                        <form onSubmit={this.handleSubmitGeocodificaDiretta}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <input type="text" name="title" onChange={this.handleGeocodificaDirettaCitta} className="form-control" placeholder="inserisci una città" />
                          <button onClick={() => this.setState({ output26: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button> <span style={{ color: '#024957', fontWeight: 'bold' }}>&nbsp; ← &nbsp;Geocodifica diretta</span>
                        </form>
                      </div>
                      <p className="fnoutput26" id="geocodifica-inversa" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output26}</p>
                    </div>

                    <h3 className="anchorbug" id="inflazione" style={{ color: '#024957' }}>Inflazione</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione fornisce i dati attuali sull'inflazione economica per 38 paesi principali. <br />
                        Le statistiche vengono generalmente aggiornate mensilmente, anche se alcuni paesi potrebbero <br />
                        rimanere indietro o aggiornarsi più raramente.</p>
                      <div>
                        <form onSubmit={this.handleSubmitInflazione}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <select className="select-form-control" name="operazione" id="operazione" onChange={this.handleInflazioneTipo}>
                            <option value="CPI"> -------------------- scegli l'indice -------------------- </option>
                            <option value="CPI">CPI (indice dei prezzi al consumo)</option>
                            <option value="HICP">HICP (indice armonizzato dei prezzi al consumo)</option>
                          </select>
                          <input type="text" name="title" onChange={this.handleInflazionePaese} className="form-control" placeholder="nome paese (in inglese)" />
                          <button onClick={() => this.setState({ output27: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                        </form>
                      </div>
                      <p className="fnoutput27" id="inflazione" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output27}</p>
                    </div>

                    <h3 className="anchorbug" id="immagini-casuali" style={{ color: '#024957' }}>Immagini casuali</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Generatore di splendide immagini casuali.</p>
                      <div>
                        <form onSubmit={this.handleSubmitImmaginiCasuali}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <select className="select-form-control" name="formato" id="formato" onChange={this.handleChangeImmaginiCasualiCategoria}>
                            <option value="nature">--- categoria ---</option>
                            <option value="nature">natura</option>
                            <option value="technology">tecnologia</option>
                            <option value="food">cibi</option>
                            <option value="still_life">natura morta</option>
                            <option value="abstract">astratte</option>
                            <option value="wildlife">fauna selvatica</option>
                          </select>
                          <input type="text" name="title" onChange={this.handleChangeImmaginiCasualiLarghezza} className="form-control" placeholder="larghezza (da 1 a 5000)" />
                          <input type="text" name="title" onChange={this.handleChangeImmaginiCasualiAltezza} className="form-control" placeholder="altezza (da 1 a 5000)" />
                          <button onClick={() => this.setState({ output28: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                        </form>
                      </div>
                      <p className="fnoutput28" id="qr-code" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output28}</p>
                    </div>

                    <h3 className="anchorbug" id="statistiche-covid" style={{ color: '#024957' }}>Statistiche Covid</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione è una fonte di facile utilizzo di statistiche mondiali dettagliate sul COVID-19.</p>
                      <div className="input-group mb-3">
                        <button onClick={() => this.setState({
                          output29: <iframe src="https://api.ncovtrack.com/covid" title="ncovtrack - COVID Statistics" height="700" width="700" allowFullScreen frameBorder="0" />,
                        })} className="btn btn-danger" type="button" id="button-addon1" style={{ marginRight: '2.5px' }}>Mappa Covid</button>
                        <button onClick={() => this.setState({
                          output29: <iframe src="https://api.ncovtrack.com/vaccine" title="ncovtrack - Vaccination Statistics" height="700" width="700" allowFullScreen frameBorder="0" />,
                        })} className="btn btn-danger" type="button" id="button-addon1" style={{ marginRight: '2.5px' }}>Mappa Vaccinazioni</button>
                        <button onClick={() => this.setState({ output29: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                      </div>
                      <p className="fnoutput29" id="qr-code" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output29}</p>
                    </div>

                    <h3 className="anchorbug" id="paesi" style={{ color: '#024957' }}>Paesi del mondo</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione fornisce informazioni su molti paesi del mondo.</p>
                      <div className="input-group mb-3">
                        <form onSubmit={this.handleSubmitPaesi}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <input type="text" name="title" onChange={this.handleChangePaesi} className="form-control" placeholder="nome paese (in inglese)" />
                          <button onClick={() => this.setState({ output30: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                        </form>
                      </div>
                      <p className="fnoutput30" id="paesi" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output30}</p>
                    </div>

                    <h3 className="anchorbug" id="lingua-testo" style={{ color: '#024957' }}>Lingua del testo</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione rileva la lingua di un determinato testo; supporta oltre 50 lingue diverse.</p>
                      <div className="input-group mb-3">
                        <form onSubmit={this.handleSubmitLinguaTesto}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <input type="text" name="title" onChange={this.handleChangeLinguaTesto} className="form-control" placeholder="inserisci un testo" />
                          <button onClick={() => this.setState({ output31: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                        </form>
                      </div>
                      <p className="fnoutput31" id="lingua-testo" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output31}</p>
                    </div>

                    <h3 className="anchorbug" id="aeroporti" style={{ color: '#024957' }}>Aeroporti</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione fornisce utili informazioni su quasi 30.000 aeroporti in tutto il mondo, <br />
                        inclusi i principali aeroporti internazionali e piccoli aeroporti regionali.</p>
                      <div className="input-group mb-3">
                        <form onSubmit={this.handleSubmitAeroporti}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <input type="text" name="title" onChange={this.handleChangeAeroporti} className="form-control" placeholder="nome aeroporto o altro" />
                          <button onClick={() => this.setState({ output32: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                        </form>
                      </div>
                      <p className="fnoutput32" id="lingua-testo" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output32}</p>
                    </div>

                    <h3 className="anchorbug" id="altitudine" style={{ color: '#024957' }}>Altitudine</h3>
                    <div style={{ marginBottom: '-100px' }}>
                      <p>Questa funzione fornisce l'altitudine di un qualsiasi punto sulla superficie terrestre <br />
                        di cui si conoscano le coordinate geografiche (latitudine e longitudine).</p>
                      <div className="input-group mb-3">
                        <form onSubmit={this.handleSubmitAltitudine}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <input type="text" name="title" onChange={this.handleChangeAltitudineLatitudine} className="form-control" placeholder="latitudine" />
                          <input type="text" name="title" onChange={this.handleChangeAltitudineLongitudine} className="form-control" placeholder="longitudine" />
                          <button onClick={() => this.setState({ output33: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                        </form>
                      </div>
                      <p className="fnoutput33" id="altitudine" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output33}</p>
                    </div>

                    <h3 className="anchorbug" id="logo-aziende" style={{ color: '#024957' }}>Logo aziende</h3>
                    <div style={{ marginBottom: '560px' }}>
                      <p>Questa funzione fornisce immagini del logo di decine di migliaia di aziende.</p>
                      <div className="input-group mb-3">
                        <form onSubmit={this.handleSubmitLogoAziende}>
                          <button type="submit" className="btn btn-success" id="button-addon1">Esegui</button>
                          <input type="text" name="title" onChange={this.handleChangeLogoAziende} className="form-control" placeholder="nome dell'azienda" />
                          <button onClick={() => this.setState({ output34: `` })} className="btn btn-danger" type="button" id="button-addon1">Cancella</button>
                        </form>
                      </div>
                      <p className="fnoutput34" id="logo-aziende" key="info" variant="info" show="false" style={{ marginTop: '7px', height: 'auto', width: '900px', color: '#024957', fontWeight: 'bold', overflow: 'hidden', wordWrap: 'break-word' }}>{this.state.output34}</p>
                    </div>

                  </Col>

                </Row>
              </div>
            </div>
          </Container>
        </Layout >

      </ThemeProvider>

    );
  }
}

export default Funzioni;