import axios from 'axios';


const apiGeolocalizzazioneIp = axios.create({
  baseURL: `https://ipxapi.com/api/`,


  // baseURL: `https://ipwho.is`,

  // https://ipwhois.io/

  headers: {
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json',
    // 'Accept': 'application/json',
    Authorization: `Bearer 4645|d611AsSfaGuMdhBMq7UpJ1VNaArz0WmEQR4Q4fQn`,
  },
});

export default apiGeolocalizzazioneIp;