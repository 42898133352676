import axios from 'axios';


const apiNinjas = axios.create({
  baseURL: `https://api.api-ninjas.com/v1`,

  headers: {
    'x-Api-Key': `xizRVebGqaJ3Ps/PN9PYgQ==t6DgqprLcGJ1v47L`,
  },
});

export default apiNinjas;