import axios from 'axios';

const apiInglese = axios.create({
  baseURL: `https://api.dictionaryapi.dev/api/v2/entries/en/`,
  // headers: {
  //   apiKey: `Bearer b5539995b87063cb657d94c050b75cfeb46b26a6f51c07df5f54802d12b19bb0`
  // }
});


export default apiInglese;