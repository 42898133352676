import axios from 'axios';

const apiMatematica = axios.create({
  baseURL: `https://newton.now.sh/api/v2`,
  // header: {
  //   'apikey': '168c94cda459a27e47ba0cd18c97740d'
  // }

  // apikey: 'BcxRLNcegJjr4VSm47WwHphjwczvkYcY'
   

// d: {
//   'engine':'yandex',
//   'text':'coffee',
//   'api_key':'secret_api_key'
// }

  // headers: {
  //   'X-RapidAPI-Key': 'SIGN-UP-FOR-KEY',
  //   'X-RapidAPI-Host': 'contextualwebsearch-websearch-v1.p.rapidapi.com'
  // }

  // headers: { 'Content-Type': 'application/json' },
//   header: {
//     'Authorization': 'Bearer JRysypFtOMyQbKXr',
//     'Content-Type': 'application/json',
//   },
//   Authorization: `Bearer ff17cbff1e0ac2bcb68c5a3acdf210ea4a849019`,
//   Data:
//   {
//     'group_guid': 'Ba1bc23dE4F',
//     'domain': 'bit.ly',
//     'long_url': 'https://dev.bitly.com/',
//   },  
});

// const apiShortener = axios.create({
//   baseURL: `https://api-ssl.bitly.com/v4/shorten`,
//   Authorization: `Bearer ff17cbff1e0ac2bcb68c5a3acdf210ea4a849019`,
//   Data:
//   {
//     'group_guid': 'Ba1bc23dE4F',
//     'domain': 'bit.ly',
//     'long_url': 'https://dev.bitly.com/',
//   },  
// });

export default apiMatematica;